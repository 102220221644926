.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* HEADER DROPDOWN */

.hero-dropdown {
  position: relative;
  display: inline-block;
}

.hero-dropdown-content {
  display: none;
  position: fixed;
  z-index: 1;
  top: 33px;

  animation-name: animate-fade;
  animation-timing-function: ease;
  animation-duration: 0.2s;
}

.hero-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.hero-dropdown:hover .hero-dropdown-content {
  display: block;
  left: 0;
  right: 0;
}

@keyframes animate-fade {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.hero-dropdown-content .hero-dropdown-items {
  display: block;
  margin-top: 16px;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 0px;
  transition: all 0.4s;
  animation-name: animate-fade;
  animation-timing-function: ease;
}

.item-qr-modal, .qr-modal {
  z-index: 100000000 !important;
  position: fixed;
  margin: 0 auto;
}

[contenteditable="true"]:focus {
  outline: none;
}

.content-panel {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.content-panel.open {
  max-height: 500px; /* You can adjust this value based on the content size */
}

.header-and-line {
  background: rgba(255, 255, 255, 0.9);
  padding-top: 10px;
  /* padding-bottom: 10px; */
}

.parent-disabled * {
  pointer-events: none !important;
}

.main-wrapper-site {
  width: 100%;

  /* @media (min-width: 1200px) {
    width: 90%;
  } */
}

.toolbar-sticky {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.mini-selector {
  left: 0 !important;
  margin-top: 50px !important;
}

.toastify-this-brah {
  min-width: 420px;
  right: 100px !important;
}

.MuiTooltip-tooltip {
  z-index: 99999999999999999999999999999999999999999999999999999999 !important;
}