body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue' !important;
}

::-webkit-scrollbar {
  display: none !important;
}

/* Hide the scrollbar by default */
.inner-scroll-div::-webkit-scrollbar {
  display: initial !important;
  opacity: 0;
  background-color: transparent;
  transition: opacity 0.2s ease-in-out;
  width: 1px; /* For vertical scrollbar */
  height: 0;  /* Hide horizontal scrollbar */
}

.inner-scroll-div {
  overflow-x: hidden;
}

/* Hide horizontal scrollbar */
.inner-scroll-div::-webkit-scrollbar-button:start:decrement,
.inner-scroll-div::-webkit-scrollbar-button:end:increment,
.inner-scroll-div::-webkit-scrollbar-horizontal {
  display: none;
}

/* Show custom scrollbar when hovered */
.inner-scroll-div:hover::-webkit-scrollbar {
  opacity: 1 !important;
}

/* Style for the thumb (the part you drag) */
.inner-scroll-div:hover::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.2); 
}

/* Style for the track (the background) */
.inner-scroll-div:hover::-webkit-scrollbar-track {
  /* background: rgba(0,0,0,0.5); */
}
.main-window::-webkit-scrollbar {
  display: initial !important;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.ais-HitsPerPage-select {
  display: none;
}

/* Change Selector Styles */
.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-tlfecz-indicatorContainer {
  /* display: none !important; */
}

.css-1t8y0t9-control {
  border-color: transparent !important;
  font-weight: 300 !important;
  cursor: pointer !important;
  padding: 0 !important;
}

.css-1t8y0t9-control:hover {
  /* background-color: #17171912; */
  box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
}

.css-1t8y0t9-control, 
.css-1t8y0t9-control:hover, 
.css-1t8y0t9-control:hover .css-tlfecz-indicatorContainer, 
.css-tlfecz-indicatorContainer, 
.css-10h0o0e-singleValue {
  font-weight: 300 !important;
  color: #171719 !important;
  cursor: pointer !important;
}

.css-1vp9nu5-control, 
.css-1vp9nu5-control:active, 
.css-1vp9nu5-control:focus {
  border-color: transparent !important;
  box-shadow: none !important;
  font-weight: 300 !important;
  cursor: pointer !important;
}



.css-1t8y0t9-control, 
.css-1vp9nu5-control {
  border-radius: 5px !important;
  padding: 0 !important;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.nogutter {
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0 !important;
}

.css-319lph-ValueContainer {
  min-width: 20px !important;
}

.row {
  margin-left: 0;
}

.input-group-text {
  background-color: #ffffff !important;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.no-left-border {
  border-left: 0px solid transparent !important;
}

.hero-form-control-wrapper {
  padding: 14px;
  border-radius: 30px;
}

.hero-form-control-wrapper-space-name {
  width: 350px;
  margin-left: 20px;
  border-radius: 30px;
  font-size: 24px;
  font-weight: 500;
}

.hero-form-control-wrapper:focus, .hero-form-control-wrapper-space-name:focus {
  color: #212529;
  background-color: #fff;
  border-color: #e82f64;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(232 47 100 / 25%);
}

.progress {
  --bs-progress-bar-bg: #e82f64;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: #17171912 !important;
  color: #171719;
}

.MuiTabs-indicator {
  background-color: #171719 !important;
}

.css-19kzrtu {
  padding: 0 !important;
}

.MuiButtonBase-root.MuiTab-root.Mui-selected {
    font-weight: 800 !important;
}

.css-b62m3t-container {
  flex-grow: 1 !important;
}

.messages-search-container .MuiFormControl-root{
  width: 100%;
}

.messages-query-container .MuiFormControl-root{
  width: 100%;
}

.MuiInputBase-root.MuiInput-root:after {
  border-bottom: 1px solid transparent !important;
}

.MuiInputBase-root.MuiInput-root:before, .MuiInputBase-root.MuiInput-root:after, .MuiInputBase-root.MuiInput-root:before {
  border-bottom: 1px solid transparent !important;
}

.MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
  color: #e82f64 !important;
}

.dropdown-toggle::after {
  display: none !important; 
}

.MuiLinearProgress-root > span:first-child {
  background-color: #e82f64 !important;
}

.MuiLinearProgress-root > span:last-child {
  background-color: #e82f6488 !important;
}

.MuiLinearProgress-bar1 {
  background-color: #e82f64 !important;
}

.MuiLinearProgress-bar2 {
  background-color: #e82f6488 !important;
}


.MuiLinearProgress-root {
  background-color: #e82f6453 !important;
  border-radius: 10px;
  top: -4px;
}

.MuiButtonGroup-root {
  box-shadow: none !important;
}

.MuiButtonGroup-groupedContainedPrimary {
  background-image: linear-gradient(135deg, #e82f64 40%, #e82f64cc) !important;
  border-radius: 10px !important;
}

.circle-picker {
  width: 100% !important;
  overflow: auto !important;
  white-space: nowrap !important;
  display: inline !important;
}

.circle-picker > span {
  display: inline-block !important;
  margin-top: 10px;
  margin-bottom: 10px;
}

.circle-picker > span:first-child {
  margin-left: 10px !important;
}

.circle-picker > span:last-child {
  margin-right: 10px !important;
}

.container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.css-hhvo2v-MuiListItem-root {
  padding: 0 !important;
}

.space-editor-wrapper-con .MuiAvatar-colorDefault {
  width: 20px !important;
  height: 20px !important;
  font-size: 12px !important;
  color: white !important;
  background: black !important;
  margin-left: -5px !important;
}

.floating-cloud-toolbar {
  background: white;
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 5px;
  box-shadow: rgb(0 0 0 / 18%) 0px 2px 4px;
  top: 5px;
  position: relative;


}

.space-editor .MuiPaper-root, .friend-adder .MuiPaper-root, .space-exploration .MuiPaper-root {
  width: 100% !important;
  margin: 0 auto !important;
  border-radius: 30px !important;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px !important;
}

.MuiButtonBase-root.MuiButton-root {
  border: 0 !important;
  border-radius: 30px !important;
}

.friend-adder-paper {
  box-shadow: none !important;
}

/* Leave Space Button */

.button-82-pushable {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  margin-left: 15px;
  margin-right: 15px;
}

.button-82-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  will-change: transform;
  transform: translateY(2px);
  transition:
    transform
    600ms
    cubic-bezier(.3, .7, .4, 1);
}

.button-82-edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(
    to left,
    hsl(340deg 100% 16%) 0%,
    hsl(340deg 100% 32%) 8%,
    hsl(340deg 100% 32%) 92%,
    hsl(340deg 100% 16%) 100%
  );
}

.button-82-front {
  display: block;
  position: relative;
  padding: 12px 27px;
  border-radius: 12px;
  font-size: 1.1rem;
  color: white;
  background: hsl(345deg 100% 47%);
  will-change: transform;
  transform: translateY(-4px);
  transition:
    transform
    600ms
    cubic-bezier(.3, .7, .4, 1);
}

@media (min-width: 768px) {
  .button-82-front {
    font-size: 1.25rem;
    padding: 12px 42px;
  }
}

.button-82-pushable:hover {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
}

.button-82-pushable:hover .button-82-front {
  transform: translateY(-6px);
  transition:
    transform
    250ms
    cubic-bezier(.3, .7, .4, 1.5);
}

.button-82-pushable:active .button-82-front {
  transform: translateY(-2px);
  transition: transform 34ms;
}

.button-82-pushable:hover .button-82-shadow {
  transform: translateY(4px);
  transition:
    transform
    250ms
    cubic-bezier(.3, .7, .4, 1.5);
}

.button-82-pushable:active .button-82-shadow {
  transform: translateY(1px);
  transition: transform 34ms;
}

.button-82-pushable:focus:not(:focus-visible) {
  outline: none;
}

.create-space-container label:not(.check) {
  font-size: 20px !important;
  transition: all 0.5s ease !important;
  margin: 10px 15px;
  background: white;
  z-index: 100;
}

.create-space-container input {
  font-size: 20px !important;
  transition: all 0.5s ease !important;
  border: 1px solid rgba(0,0,0,0.1);
  margin-bottom: 20px;
  border-radius: 50px;
  padding-left: 15px;
}

.add-item-container input {
  font-size: 20px !important;
  transition: all 0.5s ease !important;
  width: 100% !important;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 50px;
  padding: 10px;
}

.add-item-container * {
  transition: all 0.5s ease !important;
}

.add-item-container label {
  font-size: 20px !important;
  transition: all 0.5s ease !important;
  padding: 8px;
  margin-left: 20px;
  background-color: #fff;
  z-index: 1000;
}

.add-item-container .full-width-input {
  width: 100% !important;
}

.add-item-container .MuiFormControl-root {
  width: 100% !important;
}

.create-space-container {
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
}

.create-space-container input {
  font-size: 35px !important;
  transition: all 0.5s ease !important;
}

.add-item-container {
  justify-content: start !important;
}

.some-padding-on-the-left {
  padding-left: 20px !important;
}

.MuiButtonBase-root.MuiButton-root {
  min-width: 40px !important;
}

.breezy-shadow {
  box-shadow: rgb(204 219 232 / 60%) 3px 3px 22px 0px inset, rgb(255 255 255 / 50%) -1px -3px 6px 1px inset !important;
}

.space-editor .MuiTextField-root {
  width: 100% !important;
}

.grecaptcha-badge { 
  visibility: hidden;
}

.create-a-space-wrapper {
  width: 100%;
}

.footer-animation {
  animation-name: slideUp;
  animation-duration: 1.4s;
  animation-timing-function: ease-in-out;
}

.grid-item-animation:nth-child(2n + 1) {
  animation-name: slideUp2;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
}

.grid-item-animation:nth-child(2n + 2) {
  animation-name: slideUp2;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
}

iframe {
  position: relative;
  z-index: 1000;
  animation-name: fadeIN;
  animation-duration: 1.4s;
  animation-timing-function: ease-in;
}

.space-viewer-per img, .space-viewer-per h1, .space-viewer-per a, .user-viewer-per img {
  animation-name: fadeIN;
  animation-duration: 0.3s;
  animation-timing-function: ease-in;
}

.pulsate-profile {
  animation-name: fadeIN;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
}

@keyframes slideUp2 {
  0% {
    opacity: 0.5;
  }
   
  100% {
    opacity: 1;
  
  }
}

@keyframes slideUp {
  0%,
  50% {
    transform: translateY(100%);
    opacity: 0;
  }
   
  60%,
  100% {
    transform: translateY(0);
    opacity: 1;
  
  }
}


@keyframes fadeIN {
  0%,
  50% {
    opacity: 0.5;
  }
   
  60%,
  100% {
    opacity: 1;
  
  }
}

.MuiFormControlLabel-label {
  font-size: 16px !important;
}

.MuiCheckbox-root svg {
  font-size: 18px !important;
}

.MuiCheckbox-root {
  padding-left: 10px !important;
  padding-right: 5px !important;
}
/* 
.MuiCard-root {
  box-shadow: 0 0px 2px rgb(0 0 0 / 20%) !important;
} */

/* .MuiCard-root:hover {
  box-shadow: 0 0px 5px rgb(0 0 0 / 20%) !important;
} */

.framely .MuiCard-root {
  box-shadow: none !important;
}

.framely .MuiCard-root:hover {
  box-shadow: none !important;
}

.media_editor .MuiFormControlLabel-root {
  font-size: 16px !important;
  margin-left: 0px !important;
  margin-right: 0;
}

.media_editor > .MuiCheckbox-root svg {
  font-size: 18px !important;
}

.media_editor .MuiTypography-root {
  white-space: nowrap !important;
}

.over-select {
  z-index: 100000;
}

.searchresult {
  padding: 5px;
  max-width: 650px;
}

.searchresult h2 {
  font-size: 19px;
  line-height: 18px;
  font-weight: normal;
  color: rgb(29, 1, 189);
  margin-bottom: 0px;
  margin-top: 5px;
}

.MuiListItemText-root {
  /* margin-top: 0 !important; */
  margin-bottom: 0 !important;
}

.searchresult a {
  font-size: 14px;
  line-height: 14px;
  color: rgba(0,0,0,1);
  margin-bottom: 0px;
}

.searchresult button {
  font-size: 10px;
  line-height: 14px;
  color: green;
  margin-bottom: 0px;
  padding: 0px;
  border-width: 0px;
  background-color: white;
}

.Mui-selected {
  background: rgba(0,0,0,0.05) !important;
}

@keyframes float {
	0% {
    transform: translatey(0px) rotateX(0deg) rotateY(0deg);
    box-shadow: transparent 0 0 0 3px, rgb(18 18 18 / 10%) 0 6px 20px;
	}
	50% {
		transform: translatey(-10px) rotateX(0deg) rotateY(0deg);
    box-shadow: transparent 0 0 0 6px, rgb(18 18 18 / 10%) 0 6px 40px;
	}
	100% {
		transform: translatey(0px) rotateX(0deg) rotateY(0deg);
    box-shadow: transparent 0 0 0 3px, rgb(18 18 18 / 10%) 0 6px 20px;
	}
}

.floater {
  transform: translatey(0px);
	animation: float 6s ease-in-out infinite
}
/* 
code.heroml {
  padding: 20px;
  display: inline-block;
  background: #171719;
  border-radius: 15px;
  color: white;
}

pre .hljs[lang=heroml] .hljs-keyword {
  color: blue;
}

pre .hljs[lang=heroml] .hljs-variable {
  color: purple;
}

pre .hljs[lang=heroml] .hljs-string {
  color: green;
}

pre .hljs[lang=heroml] .hljs-char {
  color: red;
} */